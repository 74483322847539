
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import measure from '@/public/images/bg-measure.png';
import closet from '@/public/images/closet.jpg';
import { getInnerPagesWithDefaultLocale } from '@/services/home';
import {
  BRAND_NAME_EN,
  BRAND_NAME_FR,
  PARKING_TEXT,
  PARKING_TEXT_FR,
  SELF_STORAGE_SIZE_GUIDE,
  SELF_STORAGE_SIZE_GUIDE_FR,
} from '@/utils/constants';

const SizeCardModal = (props: any) => {
  const { onClose, open } = props;
  const { locale }: any = useRouter();
  const [title, setTitle] = useState();
  const [units, setUnits] = useState<any>([]);

  const parkingText = locale === 'en' ? PARKING_TEXT : PARKING_TEXT_FR;

  useEffect(() => {
    let defaultBrand = BRAND_NAME_EN;
    let defaultLink = SELF_STORAGE_SIZE_GUIDE;
    if (locale === 'fr') {
      defaultLink = SELF_STORAGE_SIZE_GUIDE_FR;
      defaultBrand = BRAND_NAME_FR;
    }

    getInnerPagesWithDefaultLocale(defaultLink, defaultBrand, locale)
      .then((res) => res)
      .then((data) => {
        setTitle(data?.data?.data[0]?.attributes?.Section[0]?.Title);
        setUnits(data?.data?.data[0]?.attributes?.Section[1]?.Units);
      });
  }, []);

  const handleCloseClick = () => onClose();

  const videoControl = (data: any) => {
    let ids: any = [];
    if (data === 'desktop') {
      ids = ['step11-i', 'step1-i', 'step2-i', 'step3-i', 'step5-i'];
    } else {
      ids = ['step11-j', 'step1-j', 'step2-j', 'step4-j'];
    }
    ids.forEach((id: any) => {
      const src = document.getElementById(id).getAttribute('src');
      document.getElementById(id).setAttribute('src', '');
      document.getElementById(id).setAttribute('src', src);
    });
  };

  return (
    <Modal
    show={open}
    animation={false}
    className="sizeGuide-modal"
    onHide={handleCloseClick}
    id="storageSize"
  >

    <div className="modal-header justify-content-center">
      <h2
        className={
          locale === 'en'
            ? 'section-heading mb-0'
            : 'section-heading mb-0 text-normal'
        }
      >
        {title}
      </h2>
      <button
        type="button"
        className="btn-close"
        onClick={handleCloseClick}
      ></button>
    </div>
    <div className="modal-body">
      <div className="container">
        <div className="d-none d-xl-block d-lg-block d-md-block">
          <ul className="square-info">
            {units?.length > 0 &&
              units?.map((item: any, key: number) => (
                <li key={key}>
                  <p className="square">
                    {item?.Unit_Sizes.includes('-')
                      ? item?.Unit_Sizes.split('-')[1]
                      : parkingText}
                  </p>
                </li>
              ))}
          </ul>
          <div className="wizard">
            <div className="measurement-scale">
              <Image
                sizes="(max-width: 768px) 700px, (max-width: 992px) 700px, (max-width: 1280px) 700px"
                src={measure}
                alt="Image"
                className="img-fluid thumbnail"
                width="900"
                height="60"
              />
            </div>
            <div className="scale-options">
              <ul
                className="nav nav-tabs justify-content-center"
                id="myTab"
                role="tablist"
              >
                <li
                  onClick={() => videoControl('desktop')}
                  className="nav-item flex-fill"
                  role="presentation"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Step 11"
                >
                  <a
                    className="nav-link active mx-auto d-flex align-items-center justify-content-center"
                    href="#step11"
                    id="step11-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-controls="step11"
                    aria-selected="true"
                  >
                    {locale === 'en' ? 'S' : 'P'}
                  </a>
                </li>
                <li
                  onClick={() => videoControl('desktop')}
                  className="nav-item flex-fill"
                  role="presentation"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Step 2"
                >
                  <a
                    className="nav-link mx-auto d-flex align-items-center justify-content-center"
                    href="#step2"
                    id="step2-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-controls="step2"
                    aria-selected="false"
                    title="Step 2"
                  >
                    M
                  </a>
                </li>
                <li
                  onClick={() => videoControl('desktop')}
                  className="nav-item flex-fill"
                  role="presentation"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Step 3"
                >
                  <a
                    className="nav-link mx-auto d-flex align-items-center justify-content-center"
                    href="#step3"
                    id="step3-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-controls="step3"
                    aria-selected="false"
                    title="Step 3"
                  >
                    {locale === 'en' ? 'L' : 'G'}
                  </a>
                </li>
                <li
                  onClick={() => videoControl('desktop')}
                  className="nav-item flex-fill"
                  role="presentation"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Step 4"
                >
                  <a
                    className="nav-link mx-auto d-flex align-items-center justify-content-center"
                    href="#step4"
                    id="step4-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-controls="step4"
                    aria-selected="false"
                    title="Step 4"
                  >
                    {locale === 'en' ? 'XL' : 'TG'}
                  </a>
                </li>
                <li
                  onClick={() => videoControl('desktop')}
                  className="nav-item flex-fill"
                  role="presentation"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Step 5"
                >
                  <a
                    className="nav-link mx-auto d-flex align-items-center justify-content-center"
                    href="#step5"
                    id="step5-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-controls="step5"
                    aria-selected="false"
                    title="Step 5"
                  >
                    {locale === 'en' ? 'P' : 'S'}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <ul className="size-description">
            {units?.length > 0 &&
              units?.map((item: any, i: number) => (
                <li key={i}>
                  <p className="similar">{item?.Title}</p>
                  <p className="description">{item?.Description}</p>
                </li>
              ))}
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane active"
              role="tabpanel"
              id="step11"
              aria-labelledby="step11-tab"
            >
              {units[0]?.Video_URL ? (
                <iframe
                  id="step11-i"
                  src={units[0]?.Video_URL}
                  width="750"
                  height="420"
                  className="video-frame"
                />
              ) : (
                <Image
                  sizes="(max-width: 375px) 300px, (max-width: 576px) 525px, (max-width: 768px) 700px, (max-width: 992px) 700px, (max-width: 1280px) 700px"
                  src={
                    units[0]?.Thumbnail?.data[0]?.attributes?.url
                      ? units[0]?.Thumbnail?.data[0]?.attributes?.url
                      : closet
                  }
                  alt="Fast"
                  className="img-fluid thumbnail1"
                  width="780"
                  height="420"
                />
              )}
            </div>
            {units?.length &&
              units?.map((item: any, i: number) => (
                <div
                  key={item?.id}
                  className="tab-pane fade"
                  role="tabpanel"
                  id={`step${i + 1}`}
                  aria-labelledby={`step${i + 1}-tab`}
                >
                  {item?.Video_URL ? (
                    <iframe
                      id={`step${i + 1}-i`}
                      src={item?.Video_URL}
                      width="780"
                      height="420"
                      className="video-frame"
                    />
                  ) : (
                    <Image
                      sizes="(max-width: 375px) 300px, (max-width: 576px) 525px, (max-width: 768px) 700px, (max-width: 992px) 700px, (max-width: 1280px) 700px"
                      src={
                        item?.Thumbnail?.data[0]?.attributes?.url
                          ? item?.Thumbnail?.data[0]?.attributes?.url
                          : closet
                      }
                      alt="Fast"
                      className="img-fluid thumbnail"
                      width="780"
                      height="420"
                    ></Image>
                  )}
                </div>
              ))}
          </div>
        </div>

        <div className="mobile-view-details d-block d-xl-none d-lg-none d-md-none">
          <div className="row">
            <div className="col-sm-12">
              <ul
                className="nav nav-pills nav-fill mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => videoControl('mobile')}
                >
                  <button
                    className="nav-link active"
                    id="pills-small-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-small"
                    type="button"
                    role="tab"
                    aria-controls="pills-small"
                    aria-selected="true"
                  >
                    {locale === 'en' ? 'S' : 'P'}
                  </button>
                </li>
                <li
                  onClick={() => videoControl('mobile')}
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="nav-link"
                    id="pills-medium-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-medium"
                    type="button"
                    role="tab"
                    aria-controls="pills-medium"
                    aria-selected="false"
                  >
                    M
                  </button>
                </li>
                <li
                  onClick={() => videoControl('mobile')}
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="nav-link"
                    id="pills-large-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-large"
                    type="button"
                    role="tab"
                    aria-controls="pills-large"
                    aria-selected="false"
                  >
                    {locale === 'en' ? 'L' : 'G'}
                  </button>
                </li>
                <li
                  onClick={() => videoControl('mobile')}
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="nav-link"
                    id="pills-xl-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-xl"
                    type="button"
                    role="tab"
                    aria-controls="pills-xl"
                    aria-selected="false"
                  >
                    {locale === 'en' ? 'XL' : 'TG'}
                  </button>
                </li>
                <li
                  onClick={() => videoControl('mobile')}
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="nav-link"
                    id="pills-parking-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-parking"
                    type="button"
                    role="tab"
                    aria-controls="pills-parking"
                    aria-selected="false"
                  >
                    {locale === 'en' ? 'P' : 'S'}
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-small"
                  role="tabpanel"
                  aria-labelledby="pills-small-tab"
                >
                  <div className="size-spec">
                    {units[0]?.Unit_Sizes.split('-')[1]}{' '}
                  </div>

                  <p className="ideal-for">{units[0]?.Description}</p>

                  <div className="similar-to">{units[0]?.Title}</div>

                  <div className="play-AV">
                    <div className="video-card">
                      {units[0]?.Video_URL ? (
                        <iframe
                          id="step11-j"
                          src={units[0]?.Video_URL}
                          width="280"
                          height="150"
                          className="video-frame"
                        />
                      ) : (
                        <Image
                          sizes="(max-width: 375px) 300px, (max-width: 576px) 525px, (max-width: 768px) 700px, (max-width: 992px) 700px, (max-width: 1280px) 700px"
                          src={
                            units[0]?.Thumbnail?.data[0]?.attributes?.url
                              ? units[0]?.Thumbnail?.data[0]?.attributes?.url
                              : closet
                          }
                          alt="Fast"
                          className="img-fluid thumbnail"
                          width="280"
                          height="150"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-medium"
                  role="tabpanel"
                  aria-labelledby="pills-medium-tab"
                >
                  <div className="size-spec">
                    {units[1]?.Unit_Sizes.split('-')[1]}{' '}
                  </div>

                  <p className="ideal-for">{units[1]?.Description}</p>

                  <div className="similar-to">{units[1]?.Title}</div>

                  <div className="play-AV">
                    <div className="video-card">
                      {units[1]?.Video_URL ? (
                        <iframe
                          id="step1-j"
                          src={units[1]?.Video_URL}
                          width="280"
                          height="150"
                          className="video-frame"
                        />
                      ) : (
                        <Image
                          sizes="(max-width: 375px) 300px, (max-width: 576px) 525px, (max-width: 768px) 700px, (max-width: 992px) 700px, (max-width: 1280px) 700px"
                          src={
                            units[1]?.Thumbnail?.data[0]?.attributes?.url
                              ? units[1]?.Thumbnail?.data[0]?.attributes?.url
                              : closet
                          }
                          alt="Fast"
                          className="img-fluid thumbnail"
                          width="280"
                          height="150"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-large"
                  role="tabpanel"
                  aria-labelledby="pills-large-tab"
                >
                  <div className="size-spec">
                    {units[2]?.Unit_Sizes.split('-')[1]}{' '}
                  </div>

                  <p className="ideal-for">{units[2]?.Description}</p>

                  <div className="similar-to">{units[2]?.Title}</div>

                  <div className="play-AV">
                    <div className="video-card">
                      {units[2]?.Video_URL ? (
                        <iframe
                          id="step2-j"
                          src={units[2]?.Video_URL}
                          width="280"
                          height="150"
                          className="video-frame"
                        />
                      ) : (
                        <Image
                          sizes="(max-width: 375px) 300px, (max-width: 576px) 525px, (max-width: 768px) 700px, (max-width: 992px) 700px, (max-width: 1280px) 700px"
                          src={
                            units[2]?.Thumbnail?.data[0]?.attributes?.url
                              ? units[2]?.Thumbnail?.data[0]?.attributes?.url
                              : closet
                          }
                          alt="Fast"
                          className="img-fluid thumbnail"
                          width="280"
                          height="150"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-xl"
                  role="tabpanel"
                  aria-labelledby="pills-xl-tab"
                >
                  <div className="size-spec">
                    {units[3]?.Unit_Sizes.split('-')[1]}{' '}
                  </div>

                  <p className="ideal-for">{units[3]?.Description}</p>

                  <div className="similar-to">{units[3]?.Title}</div>

                  <div className="play-AV">
                    <div className="video-card">
                      {units[3]?.Video_URL ? (
                        <iframe
                          id="step3-j"
                          src={units[3]?.Video_URL}
                          width="280"
                          height="150"
                          className="video-frame"
                        />
                      ) : (
                        <Image
                          sizes="(max-width: 375px) 300px, (max-width: 576px) 525px, (max-width: 768px) 700px, (max-width: 992px) 700px, (max-width: 1280px) 700px"
                          src={
                            units[3]?.Thumbnail?.data[0]?.attributes?.url
                              ? units[3]?.Thumbnail?.data[0]?.attributes?.url
                              : closet
                          }
                          alt="Fast"
                          className="img-fluid thumbnail"
                          width="280"
                          height="150"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-parking"
                  role="tabpanel"
                  aria-labelledby="pills-parking-tab"
                >
                  <div className="size-spec">
                    {locale === 'en' ? units[4]?.Unit_Sizes : PARKING_TEXT_FR}
                  </div>

                  <p className="ideal-for">{units[4]?.Description}</p>

                  <div className="similar-to">{units[4]?.Title}</div>

                  <div className="play-AV">
                    <div className="video-card">
                      {units[4]?.Video_URL ? (
                        <iframe
                          id="step4-j"
                          src={units[4]?.Video_URL}
                          width="280"
                          height="150"
                          className="video-frame"
                        />
                      ) : (
                        <Image
                          sizes="(max-width: 375px) 300px, (max-width: 576px) 525px, (max-width: 768px) 700px, (max-width: 992px) 700px, (max-width: 1280px) 700px"
                          src={
                            units[4]?.Thumbnail?.data[0]?.attributes?.url
                              ? units[4]?.Thumbnail?.data[0]?.attributes?.url
                              : closet
                          }
                          alt="Fast"
                          className="img-fluid thumbnail"
                          width="280"
                          height="150"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  );
};

export default SizeCardModal;
